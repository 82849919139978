import { NextPageContext } from 'next'
import nookies from 'nookies'

import { CookieKeys } from '../components/user-info-provider/user-info-provider'

export const getTokenFromCookie = (ctx: NextPageContext) => {
  return nookies.get(ctx)[CookieKeys.TOKEN]
}

export const getAuthHeaderFromCtx = (ctx: NextPageContext): { 'X-Auth-Token': string } | undefined => {
  const token = getTokenFromCookie(ctx)
  if (token) {
    return {
      'X-Auth-Token': token,
    }
  }
}
