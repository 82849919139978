import { NextPageContext } from 'next'
import nookies from 'nookies'

import { getTokenFromCookie } from './tokenHelpers'
import { PreviewViewsType } from '../components/user-info-provider/user-context'
import { CookieKeys, UserCookieValue } from '../components/user-info-provider/user-info-provider'

export const getStandardCookiesFromCtx = (ctx: NextPageContext): UserCookieValue => {
  const cookies: UserCookieValue = {
    [CookieKeys.TOKEN]: getTokenFromCookie(ctx) || '',
    [CookieKeys.CONDENSED_SUMMARY]: !!nookies.get(ctx)[CookieKeys.CONDENSED_SUMMARY],
    [CookieKeys.PREVIEW_VIEW]: nookies.get(ctx)[CookieKeys.PREVIEW_VIEW] as PreviewViewsType,
  }

  return cookies
}
